<template>
  <section>
    <form v-on:submit.prevent class="form" role="form" ref="form">
      <div class="row">
        <div class="form-group col-md-6">
          <FormParentSelection
            v-if="parent && parent.id != ''"
            v-model="parent"
            :locker="connector_id ? true : false"
            :service="parentService"
            :value="parent || null"
            :disabled="connector_id ? true : false"
            label="connector"
          />
        </div>
      </div>
      <div v-if="ready">
        <FormCommonFieldsInline
          v-model="device"
          :errors="errors"
          :isModelBased="isModelBased"
        />
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs">
            <li
              :class="{
                active: 'tab_connector_home' == activeTab
              }"
              @click.stop.prevent="activeTab = 'tab_connector_home'"
            >
              <a href="#tab_connector_home" data-toggle="tab">
                <ToolTip
                  icon=""
                  :title="$t(`tab_label.tab_connector_home`)"
                  @click="activeTab = 'tab_connector_home'"
                >
                  <i class="fa fa-device"></i>
                  <span class="hidden-sm hidden-xs">
                    {{ $tc("general", 1).toUpperCase() }}
                  </span>
                </ToolTip>
              </a>
            </li>
            <li
              :class="{
                active: 'tab_custom_fields' == activeTab
              }"
              @click.stop.prevent="activeTab = 'tab_custom_fields'"
            >
              <a href="#tab_custom_fields" data-toggle="tab">
                <ToolTip
                  icon=""
                  :title="$t(`tab_label.tab_custom_fields`)"
                  @click="activeTab = 'tab_custom_fields'"
                >
                  <i class="fa fa-gears"></i>
                  <span class="hidden-sm hidden-xs">
                    {{ $tc("extended_properties", 1).toUpperCase() }}
                  </span>
                </ToolTip>
              </a>
            </li>
          </ul>

          <!-- begin tab container -->
          <div class="tab-content">
            <!-- general -->
            <div
              class="tab-pane"
              id="tab_connector_home"
              :class="{
                active: activeTab == 'tab_connector_home'
              }"
            >
              <!-- begin virtual devices -->
              <div class="row no-select" style="margin-top: 15px">
                <div class="col-md-6" v-if="isMQTT">
                  <div
                    class="form-group"
                    :class="{'has-error': !mqttLabelLocked && !label}"
                  >
                    <label for=""
                      >{{ $t("topic") }} MQTT
                      <ToolTip :title="$t('hints.mqtt_topic')" />
                    </label>

                    <div class="input-group">
                      <div
                        v-if="!mqttLabelLocked"
                        class="input-group-addon input-prefix-label"
                        :style="{
                          'background-color': isModelBased ? '#eee' : 'inherit'
                        }"
                      >
                        {{ topicPrefix }}/
                      </div>
                      <template v-if="mqttLabelLocked">
                        <input
                          :value="$tc('standard', 1)"
                          :disabled="true"
                          class="form-control text-center disabled"
                        />
                        <div
                          class="input-group-addon btn"
                          @click="toggleMqttLabel"
                        >
                          <i class="fa fa-lock"></i>
                        </div>
                      </template>
                      <template v-else>
                        <input
                          class="form-control no-focus-border"
                          ref="inpLabel"
                          :value="label"
                          :disabled="isModelBased"
                          @input="setLabel($event)"
                          style=""
                        />
                        <MQTTTopicSpan
                          class="input-group-addon btn"
                          :entry="device"
                        />
                        <div
                          class="input-group-addon btn"
                          @click="toggleMqttLabel"
                        >
                          <i class="fa fa-unlock"></i>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6" v-if="deviceModel">
                  <label class="checkbox-inline" v-if="cloneEnabled">
                    <input
                      type="checkbox"
                      v-model="keep_device_reference"
                      style="margin-right: 5px"
                    />
                    {{ $t("keep_screen_references_among_devices") }}
                    <ToolTip
                      :title="
                        $t('hints.keep_screen_references_among_devices', {
                          item: deviceModel.name
                        })
                      "
                    />
                  </label>
                  <label v-else>
                    {{ $t("inherit_template_settings") }}
                    <ToolTip
                      :title="
                        $t('hints.inherit_template_settings', {
                          item: $tc('device', 1)
                        })
                      "
                    />
                  </label>
                  <div
                    class="input-group"
                    :title="`#${deviceModel.id} - ${deviceModel.name}`"
                    v-if="deviceModel"
                  >
                    <div class="input-group-addon">
                      {{ $tc("device", 1) }}
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      :value="deviceModel.name"
                    />
                  </div>
                </div>
                <div
                  class="form-group col-md-6"
                  v-if="deviceCollectorList.length"
                >
                  <label for="data_collector_device_id">
                    {{ $t("titles.data_collector_device") }}
                    <ToolTip
                      :title="$parent.$t('hints.data_collector_device')"
                    />
                  </label>
                  <select
                    class="form-control"
                    v-model="device.data_collector_device_id"
                    :disabled="
                      device.id ||
                        isMQTT ||
                        !deviceCollectorList.length ||
                        keep_device_reference ||
                        device.reference_device_id
                    "
                    id="data_collector_device_id"
                  >
                    <option
                      :value="device.id"
                      v-if="
                        !isMQTT || device.id == device.data_collector_device_id
                      "
                    >
                      {{
                        $t("itself", {
                          name: $tc("device", 1).toLowerCase()
                        })
                      }}
                    </option>
                    <template v-for="item in deviceCollectorList">
                      <option
                        v-if="item.id != device.id"
                        :value="item.id"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
              <!-- end virtual devices -->

              <!-- begin process area / screen selection -->
              <div class="row no-select" style="margin-top: 15px">
                <div class="col-md-7">
                  <ProcessAreaSelection
                    v-bind:parentProcessAreaId="parentProcessAreaId"
                    v-bind:value="process_area"
                    v-model="process_area"
                    :disabled="
                      isModelBased ||
                      keep_device_reference ||
                      device.reference_device_id
                        ? true
                        : false
                    "
                  />
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label for :class="{'text-red': !screenExists}">
                      {{ $tc("screen", 1) }}
                      <ToolTip :title="$t('hints.screen_selection')" />
                    </label>
                    <ScreenSelector
                      v-model="screenId"
                      :default="
                        device && device.connector && device.connector.screen_id
                      "
                      class="screen-selector"
                      :disabled="
                        isModelBased ||
                          keep_device_reference ||
                          device.reference_device_id
                      "
                    >
                      <template #addonAfter>
                        <div
                          class="input-group-addon btn"
                          :class="{'addon-link': canEditScreen}"
                          @click.stop.prevent="onEditScreen"
                          :disabled="!canEditScreen"
                          :title="$t('edit')"
                        >
                          <i class="fa fa-pencil"></i>
                        </div>
                      </template>
                    </ScreenSelector>
                  </div>
                </div>
              </div>
              <!-- end process area / screen selection -->

              <div class="row no-select" style="margin-top: 15px">
                <div
                  class="form-group col-md-4 col-xs-6"
                  :class="{
                    'has-error': dataCollectorDevice.device_address === ''
                  }"
                  v-if="!isMQTT"
                >
                  <label for="address">
                    {{ $t("device_address") }}
                    <ToolTip :title="$parent.$t('hints.device_address')" />
                  </label>
                  <input
                    type="number"
                    class="form-control text-right"
                    data-testid="device-address"
                    v-model="dataCollectorDevice.device_address"
                    id="address"
                    min="0"
                    max="255"
                    step="1"
                    :disabled="isModelBased || isVirtual"
                  />
                </div>
                <div class="form-group col-md-2 col-xs-6" v-if="!isMQTT">
                  <label for="address" style="display: block">{{
                    $t("type")
                  }}</label>
                  <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      data-testid="device-is-hi"
                      v-model="dataCollectorDevice.is_hi_device"
                      id="is_hi_device"
                      :disabled="isModelBased || isVirtual"
                    />
                    {{ $t("is_hi_device") }}
                    <ToolTip :title="$parent.$t('hints.is_hi_device')" />
                  </label>
                </div>
                <div class="form-group col-md-3 col-xs-12">
                  <label class="no-select" style="height: 20px;">
                    <input
                      type="checkbox"
                      v-model="historyDataToggle"
                      data-testid="data-to-enable-history"
                      id="data_to_enable_history"
                      :disabled="!isHistoryDataValueEnabled"
                    />
                    {{ $t("titles.data_to_enable_history") }}
                    <ToolTip :title="$t('hints.data_to_enable_history')" />
                  </label>
                  <template v-if="historyDataToggle">
                    <ControlDataSelector
                      label=""
                      :addon="$tc('data')"
                      :expressionToolbar="false"
                      :connectorId="connector_id"
                      :multiConnector="false"
                      :value="dataToEnableHistoryId"
                      :parser="dataHistoryItems"
                      :disabled="!isHistoryDataValueEnabled"
                      @input="dataToEnableHistoryId = $event"
                    >
                    </ControlDataSelector>
                    <div class="input-group" style="margin-top: -15px">
                      <!-- <div class="input-group-addon">{{ $t("value") }}</div> -->
                      <div
                        class="input-group-addon btn operator-addon"
                        :class="{
                          disabled:
                            !selectedDataHistoryActivator ||
                            !isHistoryDataValueEnabled
                        }"
                      >
                        <div
                          class="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          :title="
                            operatorToEnableHistory &&
                              operatorToEnableHistory.name
                          "
                        >
                          {{
                            operatorToEnableHistory &&
                              operatorToEnableHistory.comparison_operator
                          }}
                          <span class="caret"></span>
                        </div>
                        <ul class="dropdown-menu">
                          <li
                            v-for="(item, ix) in logicalOperators"
                            :key="ix"
                            :title="item.name"
                            @click="operatorToEnableHistory = item.id"
                          >
                            <a href="#">{{ item.comparison_operator }}</a>
                          </li>
                        </ul>
                      </div>
                      <input
                        ref="valueToEnableHistory"
                        :type="
                          !selectedDataHistoryActivator ||
                          selectedDataHistoryActivator.type !== 'string'
                            ? 'number'
                            : 'text'
                        "
                        class="form-control text-center"
                        v-model="valueToEnableHistory"
                        :disabled="
                          !selectedDataHistoryActivator ||
                            !isHistoryDataValueEnabled
                        "
                        :placeholder="historyDataValuePlaceHolder"
                      />
                      <div
                        class="input-group-addon btn"
                        :class="{
                          disabled: !isHistoryDataValueEnabled
                        }"
                        @click.stop.prevent="
                          valueToEnableHistory = isHistoryDataValueEnabled
                            ? ''
                            : valueToEnableHistory;
                          $refs.valueToEnableHistory.focus();
                        "
                      >
                        <i class="fa fa-close"></i>
                      </div>
                    </div>
                  </template>
                  <input
                    v-else
                    class="form-control disabled"
                    disabled
                    :value="$t('not_available')"
                  />
                </div>
              </div>

              <div class="row no-select" v-if="!isMQTT">
                <div class="col-lg-4 col-xs-7">
                  <div
                    class="form-group"
                    :class="{
                      'has-error':
                        dataCollectorDevice.local_storage_enabled &&
                        !(
                          dataCollectorDevice.local_storage_initial_address ||
                          dataCollectorDevice.local_storage_initial_address == 0
                        )
                    }"
                  >
                    <label class="no-select" style="height: 20px;">
                      <input
                        type="checkbox"
                        v-model="dataCollectorDevice.local_storage_enabled"
                        data-testid="local-enabled"
                        id="local_storage_enabled"
                        :disabled="isModelBased || isVirtual || isFreePlan"
                      />
                      {{ $t("local_storage_enabled") }}
                      <ToolTip :title="$t('hints.local_storage_enabled')" />
                    </label>
                    <div
                      class="input-group"
                      v-if="dataCollectorDevice.local_storage_enabled"
                    >
                      <div
                        class="input-group-addon"
                        data-toggle="popover"
                        :data-content="
                          $t('hints.local_storage_initial_address')
                        "
                      >
                        {{ $t("local_storage_initial_address") }}
                      </div>
                      <input
                        type="number"
                        class="form-control text-right"
                        data-testid="local-address"
                        v-model="
                          dataCollectorDevice.local_storage_initial_address
                        "
                        id="local_storage_initial_address"
                        min="0"
                        max="65535"
                        step="1"
                        :disabled="isModelBased || isVirtual || isFreePlan"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-xs-5"
                  v-if="dataCollectorDevice.local_storage_enabled"
                >
                  <div
                    class="form-group"
                    :class="{
                      'has-error': !dataCollectorDevice.local_storage_version_id
                    }"
                  >
                    <label>
                      {{ $t("titles.local_storage_version") }}
                      <ToolTip :title="hintLocalStorageVersion" />
                    </label>
                    <div class="input-group">
                      <div
                        class="input-group-addon"
                        data-toggle="popover"
                        :data-content="$t('hints.local_storage_version')"
                      >
                        {{ $t("local_storage_version_id") }}
                      </div>
                      <select
                        name="local_storage_version_id"
                        id
                        class="form-control"
                        data-testid="local-version"
                        v-model="dataCollectorDevice.local_storage_version_id"
                        v-bind:disabled="isModelBased || isVirtual"
                      >
                        <option
                          v-for="(item, ix) in storageVersions"
                          v-bind:key="ix"
                          v-bind:value="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-5 col-xs-7"
                  v-if="dataCollectorDevice.local_storage_enabled"
                >
                  <TimeZoneSelectionForm
                    class="form-group-timezone"
                    :class="{
                      'has-error': !dataCollectorDevice.local_storage_timezone
                    }"
                    v-bind:value="dataCollectorDevice.local_storage_timezone"
                    v-model="dataCollectorDevice.local_storage_timezone"
                    :disabled="isVirtual"
                  />
                </div>
              </div>

              <div v-if="!dataCollectorDevice.local_storage_enabled"></div>

              <FormInlineModel
                v-if="isModel && !cloneEnabled && device.id"
                v-model="connectorModel"
                v-bind:isConnector="false"
                style="margin-top: 15px"
              />

              <div
                class="row no-select"
                style="margin-top: 3rem"
                v-if="device_id && !isModel && !cloneEnabled"
              >
                <ClearHistory
                  target="device"
                  :targetId="device_id"
                  class="col-md-4"
                />
              </div>
            </div>
            <!-- end general -->

            <!-- begin extended properties -->
            <div
              class="tab-pane"
              id="tab_custom_fields"
              :class="{
                active: activeTab == 'tab_custom_fields'
              }"
              style="padding: 10px"
            >
              <FormExtendedProperties
                v-if="ready && extendedPropertiesSchema"
                class="extended-properties-panel fade in"
                :restoreButton="true"
                :resetButton="true"
                :importButton="true"
                :modeButton="false"
                :mode="extendedPropertiesMode"
                :fields="extendedPropertiesSchema"
                v-model="extendedProperties"
                @restore="onRestoreExtendedProperties"
                @mode="extendedPropertiesMode = $event"
              >
              </FormExtendedProperties>
            </div>
            <!-- begin extended properties -->
          </div>
          <!-- end tab container -->
        </div>
      </div>
      <div v-if="no_data">
        <div class="alert alert-info">
          <i class="icon fa fa-warning"></i> {{ $t("no_data") }}
        </div>
      </div>
      <FormFooterToolbar
        v-if="ready"
        v-bind:remove="device_id && !isModelBased ? true : false"
        v-bind:busy="busy"
        v-bind:valid="isValid"
        v-bind:clone="true"
        v-bind:value="cloneEnabled"
        v-model="cloneEnabled"
        v-on:buttonCancelClick="onButtonCancelClick"
        v-on:buttonSaveClick="onButtonSaveClick"
        v-on:buttonCloneClick="onButtonCloneClick"
        v-on:buttonRemoveClick="onButtonRemoveClick"
        rule="EstacaoEscrita"
      />
    </form>
  </section>
</template>

<script>
import FormBase from "@/components/registration/form-base.vue";
import FormParentSelection from "@/components/registration/form-parent-selection.vue";
import ProcessAreaSelection from "@/components/processarea-selection.vue";
import FormCommonFieldsInline from "@/components/registration/form-common-fields-inline.vue";
import FormInlineModel from "@/components/registration/form-inline-model.vue";
import ToolTip from "@/components/tooltip.vue";
import ClearHistory from "@/components/history/clear-history";
import DeviceService from "@/services/device.js";
import ConnectorService from "@/services/connector.js";
import ScreenSelector from "@/components/editor/screen-selector.vue";
import TimeZoneSelectionForm from "@/components/time-zone-selection-form.vue";
import MQTTTopicSpan from "@/components/registration/mqtt-topic-span.vue";
import {
  deviceListAdapter,
  extendedPropertiesSetter,
  extendedPropertiesDeviceAdapter
} from "@/services/device";
import {mqttTopic} from "@/services/equipment.js";
import {contractPlanConst} from "@/assets/constants.js";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import FormExtendedProperties from "@/components/registration/form-extended-properties.vue";
import {defaultSchema} from "@/components/registration/form-extended-properties.vue";

// Localization
import messages from "@/i18n/device";
function defaultData() {
  return {
    // basicDevice: {
    //   id: "",
    //   is_hi_device: true, // it seems it will changed
    //   device_address: "",
    //   data_collector_device_id: ""
    // },
    device: {
      id: "",
      name: "",
      description: "",
      enabled: true,
      process_area: null, // it seems it will be changed
      inherits_parent_process_area: true,
      connector: null,
      device_address: "1",
      is_hi_device: true, // it seems it will changed
      local_storage_enabled: false,
      local_storage_version_id: 2,
      local_storage_initial_address: 0,
      portal_data: {},
      user_data: {},
      local_storage_timezone: "UTC",
      data_collector_device_id: "",
      screen_id: "",
      label: "",
      data_to_enable_history_id: "",
      value_to_enable_history: "",
      comparison_to_enable_history: ""
    },
    process_area: {
      inherits_parent_process_area: true,
      id: 0
    },
    parent: {
      id: "",
      name: ""
    },
    tooltipInitialized: false,
    connectorModel: {
      propagate: false
    },
    keep_device_reference: false,
    history_data_toggle: false,
    mqttLabelLocked: true,
    activeTab: "tab_connector_home",
    extendedPropertiesMode: "editor",
    originalExtendedProperties: null,
    extendedPropertiesSchema: defaultSchema()
  };
}

export default {
  name: "FormDevice",
  extends: FormBase,
  i18n: {messages},
  components: {
    ToolTip,
    ProcessAreaSelection,
    FormParentSelection,
    ClearHistory,
    FormCommonFieldsInline,
    FormInlineModel,
    TimeZoneSelectionForm,
    ScreenSelector,
    MQTTTopicSpan,
    ControlDataSelector,
    FormExtendedProperties
  },
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    },
    device_id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return defaultData();
  },
  computed: {
    connectorId() {
      return parseInt(
        this.connector_id || this.$route.params.connector_id || 0
      );
    },
    deviceId() {
      return parseInt(this.device_id || this.$route.params.device_id || 0);
    },
    connector() {
      return this.device && this.device.connector && this.device.connector.id
        ? this.device.connector
        : null;
    },
    parentProcessAreaId() {
      return (
        (this.device &&
          this.device.connector &&
          this.device.connector.id &&
          this.device.connector.process_area &&
          this.device.connector.process_area.id) ||
        0
      );
    },
    isLocalStorageValid() {
      return !this.dataCollectorDevice.local_storage_enabled ||
        (this.dataCollectorDevice.local_storage_enabled &&
          this.dataCollectorDevice.local_storage_version_id &&
          this.dataCollectorDevice.local_storage_timezone &&
          (this.dataCollectorDevice.local_storage_initial_address ||
            this.dataCollectorDevice.local_storage_initial_address == 0))
        ? true
        : false;
    },
    isValid() {
      return this.process_area.id &&
        this.device &&
        this.device.name &&
        this.isLocalStorageValid &&
        (this.isVirtual || this.device.device_address !== "") &&
        !this.errors
        ? true
        : false;
    },
    storageVersions() {
      return (
        this?.$root?.config?.references?.local_storage_versions ?? []
      ).sort((version) => version.id);
    },
    payload() {
      let payload = {...defaultData().device, ...this.device};

      if (this.device.id) {
        payload.id = this.device.id;
      }

      // payload.device_address = this.basicDevice.device_address;
      // payload.is_hi_device = this.basicDevice.is_hi_device;

      // TODO: PAYLOAD for device collector
      if (payload.data_collector_device_id == this.device.id) {
        delete payload.data_collector_device_id;
      }

      if (this.isMQTT || payload.data_collector_device_id) {
        // MQTT does not allow user interaction, so overwrite any standard value
        payload.device_address = this?.dataCollectorDevice?.device_address;
        payload.local_storage_version_id = this?.dataCollectorDevice?.local_storage_version?.id;
        payload.is_hi_device = this?.dataCollectorDevice?.is_hi_device;
        payload.local_storage_enabled = this?.dataCollectorDevice?.local_storage_enabled;
      }
      payload.portal_data = payload.portal_data || {};
      payload.user_data = payload.user_data || {};

      if (this.keep_device_reference && this.cloneEnabled) {
        payload.reference_device_id = this.device.id;
      }

      payload.connector_id =
        this.device.connector && this.device.connector.id != 0
          ? this.device.connector.id
          : (this.parent && this.parent.id) || 0;

      if (this.connectorModel.propagate) {
        payload.apply_changes_to_instances = true;
      }
      if (
        !this?.device?.screen_id ||
        parseInt(this?.device?.screen_id) > 999999999 ||
        parseInt(this?.device?.connector?.screen_id) ==
          parseInt(this?.device?.screen_id)
      ) {
        payload.screen_id = null;
      }
      payload.inherits_parent_process_area = this.process_area.inherits_parent_process_area;
      payload.process_area_id = this.process_area.id || "";

      if (!this.history_data_toggle) {
        payload.data_to_enable_history_id = null;
      }
      if (!payload.local_storage_timezone) {
        delete payload.local_storage_timezone;
      }
      delete payload.local_storage_version;
      delete payload.connector;
      delete payload.process_area;
      return payload;
    },
    removalMessage() {
      let msg = "";
      let item = this.device;
      if (item.connector.base_model) {
        let message = this.$t("removal_model_message");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = this.$tc("device");
        let value = item.name;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p>${message}<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div></p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      } else {
        msg = this.warningContent(
          "device",
          item.name,
          "you_wont_be_able_to_revert_this"
        );
      }
      return msg;
    },
    errors() {
      let entry = null;
      if (!this.device.name) return {name: "invalid_name"};

      // Nome de Dispositivo com apenas números não é aceito
      if (this.device.name == parseInt(this.device.name) + "") {
        entry = entry || {};
        entry.name = "invalid_name";
      }

      return entry;
    },
    isMQTT() {
      return this?.device?.connector?.protocol?.is_mqtt_protocol || false;
    },
    topicPrefix() {
      return mqttTopic(this.device, -1);
    },
    label: {
      set(value) {
        this.$set(this.device, "label", this.$utils.asLabel(value));
      },
      get() {
        return this?.device?.label || "";
      }
    },
    deviceList() {
      return this.$store.getters["dashboard/deviceList"] || [];
    },
    deviceCollectorList() {
      let lst = this.deviceList.filter(
        (d) => parseInt(d.connector_id) == parseInt(this.connectorId)
      );
      if (!lst.length) return [];
      if (this.isMQTT) {
        // mqtt connector does not allow more than one
        return [
          lst.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))[0]
        ];
      } else {
        // regular connector -
        return lst
          .filter((i) => {
            return (
              (this.device.id && parseInt(this.device.id) == parseInt(i.id)) ||
              !i.data_collector_device_id ||
              parseInt(i.data_collector_device_id) == parseInt(i.id)
            );
          })
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      }
    },
    isVirtual() {
      return this.device.id !== this.device.data_collector_device_id;
    },
    dataCollectorDevice() {
      let device =
        (this.isVirtual &&
          this.deviceList.find(
            ({id}) => id == this.device.data_collector_device_id
          )) ||
        this.device;
      if (
        !device.local_storage_version_id &&
        device?.local_storage_version?.id
      ) {
        device.local_storage_version_id = device?.local_storage_version?.id;
      }
      return device;
    },
    deviceModel() {
      // reference_device_id
      if (!this.cloneEnabled || !this.device.id) return "";
      let device = this.deviceList.find(
        ({id}) => parseInt(id) == parseInt(this.device.id)
      );
      let modelId = device.reference_device_id || device.id || "";
      // (this.device.id) ||
      // this.device.reference_device_id ||
      // "";
      return modelId
        ? this.deviceList.find(({id}) => parseInt(id) == parseInt(modelId))
        : null;
    },
    screenList() {
      return (this.$store.getters["dashboard/screens"] || []).filter(
        ({id, deleted_at, portal_data}) =>
          id > -1 && !deleted_at && (!portal_data || !portal_data.excluded_at)
      );
    },
    screenSelection() {
      return (this.contract && this.contract.allowed_custom_screens) || false;
    },
    screenExists() {
      let screen_id = this.device.screen_id;
      if (!screen_id) return true;
      let screen = this.screenList.find(
        ({id, deleted_at}) => id == screen_id && !deleted_at
      );
      return Boolean(screen);
    },
    canEditScreen() {
      if (!this.screenSelection) return false;
      let screen = this.screenList.find(
        ({id, deleted_at}) => id == this.device.screen_id && !deleted_at
      );
      return screen && !screen.public;
    },
    dataToEnableHistoryId: {
      set(value) {
        this.$set(this.device, "data_to_enable_history_id", value);
      },
      get() {
        return this?.device?.data_to_enable_history_id || "";
      }
    },
    valueToEnableHistory: {
      set(value) {
        this.$set(this.device, "value_to_enable_history", value);
      },
      get() {
        return this?.device?.value_to_enable_history || "";
      }
    },
    operatorToEnableHistory: {
      set(value) {
        if (!this.historyDataToggle) return;
        this.device.comparison_to_enable_history_id = parseInt(value);
        this.$set(this.device, "comparison_to_enable_history", {
          id: parseInt(value)
        });
      },
      get() {
        return (
          this.logicalOperators.find(
            ({id}) =>
              parseInt(id) ==
              parseInt(
                (this.historyDataToggle &&
                  this?.device?.comparison_to_enable_history?.id) ??
                  1
              )
          ) || null
        );
      }
    },
    historyDataToggle: {
      set(value) {
        if (value && !this.history_data_toggle) {
          this.device.comparison_to_enable_history = "";
          this.device.data_to_enable_history_id = "";
          this.device.value_to_enable_history = "";
        }
        this.history_data_toggle = value;
      },
      get() {
        return this.history_data_toggle;
      }
    },
    selectedDataHistoryActivator() {
      return (
        (this.dataToEnableHistoryId &&
          this.$store.getters["dashboard/dataList"].find(
            ({id}) => parseInt(id) === parseInt(this.dataToEnableHistoryId)
          )) ||
        null
      );
    },
    logicalOperators() {
      const dft = {id: 1, name: "=", comparison_operator: "="};
      if (!this.selectedDataHistoryActivator) return [dft];
      const lst = this?.$root?.config?.references?.alarm_trigger_conditions ?? [
        lst
      ];
      return this.selectedDataHistoryActivator?.type == "bool"
        ? lst.filter(
            ({comparison_operator}) =>
              comparison_operator == "==" || comparison_operator == "!="
          )
        : lst;
    },
    historyDataValuePlaceHolder() {
      return `${this.$t("value")} ${this?.selectedDataHistoryActivator
        ?.current_value?.value ?? "?"}`;
    },
    isHistoryDataValueEnabled() {
      return (
        !this.isModelBased &&
        (!this.isVirtual ||
          (!this?.device?.reference_device_id && !this.cloneEnabled))
      );
    },
    isFreePlan() {
      return (
        false || this?.contract?.contract_plan?.id == contractPlanConst.FREE
      );
    },
    screenId: {
      set(value) {
        this.device.screen_id =
          value !== "" && value !== null && value !== undefined && !isNaN(value)
            ? parseInt(value)
            : "";
      },
      get() {
        return this.device.screen_id;
      }
    },
    hintLocalStorageVersion() {
      let hint = [];

      (this.storageVersions || []).forEach((version) => {
        hint.push(`${version.name}: ${version.description}`);
      });

      return hint.join("<br>");
    },
    extendedProperties: {
      set(value) {
        extendedPropertiesSetter(this.device, value);
      },
      get() {
        return (this?.device?.portal_data?.extended_properties || []).map(
          (item) => {
            if (this.extendedPropertiesMode == "viewer") {
              let vlr = (this?.device?.user_data?.extended_properties ?? {})[
                item.name
              ];
              if (vlr !== undefined && vlr.toString().trim() !== "") {
                return {...item, value: vlr};
              }
            }
            return item;
          }
        );
      }
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    }
  },
  watch: {
    "device.name": {
      handler(n, o) {
        if (this.isMQTT) {
          if (
            (!this.mqttLabelLocked && !this.label) ||
            this.label == this.$utils.asLabel(o)
          ) {
            this.label = n;
          }
        }
        this.$emit(
          "titleChanged",
          `${(this.deviceId &&
            (this.cloneEnabled ? this.originalName : this.device.name)) ||
            this.$tc("new", 1)}${
            this.cloneEnabled ? " (" + this.$t("copying") + ")" : ""
          }`
        );
      },
      deep: true
      // immediate: true
    },
    // connector(n, o) {
    //   if (n && o && n.id != o.id) {
    //     let self = this;
    //     self.ready = false;
    //     self.$nextTick(() => {
    //       self.ready = true;
    //     });
    //   }
    // },
    parent(n) {
      if (
        n &&
        n.id !=
          ((this.device && this.device.connector && this.device.connector.id) ||
            "")
      ) {
        let self = this;
        self.busy = true;
        self.fetchConnector(n.id).then((ret) => {
          if (ret) {
            self.$set(self.device, "connector", ret);
          }
          self.ready = false;
          self.$nextTick(() => {
            self.ready = true;
            self.busy = false;
          });
        });
      }
    },
    busy(n) {
      this.$emit("loading", n);
    },
    async "device.local_storage_enabled"(n) {
      if (!this.tooltipInitialized && n) {
        await this.$nextTick();
        $(this.$el)
          .find("[data-toggle=popover]")
          .popover({
            delay: {show: 300},
            placement: "auto",
            trigger: "hover"
          });
        this.tooltipInitialized = true;
      }
    },
    device_id(n, o) {
      if (!n && o) {
        this.ready = false;
        this.resetData();
        this.$nextTick(() => {
          this.setup();
        });
      }
    },
    payload(n) {
      if (n && typeof this.$parent.updateHash == "function") {
        this.$parent.updateHash(n);
      }
    },
    isLoading(n) {
      if (!n) {
        this.setup();
      }
    }
  },
  methods: {
    setLabel($event) {
      if (this.isModelBased) return;
      this.label = $event?.target?.value || "";
      $event.target.value = this.label;
    },
    resetData() {
      let data = defaultData();
      Object.keys(data).forEach((k) => {
        if (Object.prototype.hasOwnProperty.call(this.$data, k)) {
          this.$data[k] = data[k];
        }
      });
    },
    async fetchDevice() {
      let self = this;
      return new Promise((resolve) => {
        this.service
          .get(self.device_id, this.contract && this.contract.id)
          .then((ret) => {
            resolve(ret);
          });
      });
    },
    async fetchConnector(id) {
      return new Promise((resolve) => {
        let srv = new ConnectorService();
        srv.get(id).then((ret) => {
          resolve(ret);
        });
      });
    },
    async fetchDevices() {
      this.$store.dispatch("dashboard/fetchResourcesFrom", {
        resource: "device",
        connectorId: this.connectorId,
        forceUpdate: true
      });
    },
    async fetchDataList() {
      var query = {
        resource: "data",
        connectorId: this.connectorId,
        forceUpdate: true
      };
      this.$store.dispatch("dashboard/fetchResourcesFrom", query);
    },
    save(stay, addAnother) {
      let self = this;
      let isNew = !self.device.id;
      let isModel = self.isModel;
      this.validateResourceQuota(
        "connector_plural",
        self.device.is_hi_device
          ? this.contract.maximum_connectors
          : this.contract.maximum_third_party_connectors,
        (self.device.is_hi_device
          ? this.contract.registered_connectors
          : this.contract.registered_third_party_connectors) -
          (!isNew || isModel ? 1 : 0)
      ).then((resp) => {
        if (resp == "proceed") {
          let payload = self.payload;
          self.busy = true;
          this.service.save(payload).then((ret) => {
            self.busy = false;
            if (self.validateSaveResponse(ret)) {
              self.onSaveSuccess(deviceListAdapter(ret), "device");
              self.showAlert(() => {
                if (addAnother) {
                  if (
                    this.$route.path !=
                    `/dashboard/edit/connector/${this.connectorId}/device/0`
                  )
                    this.$router.push(
                      `/dashboard/edit/connector/${this.connectorId}/device/0`
                    );
                  this.$emit("updateKey");
                } else if (stay) {
                  if (self.$route.path.endsWith("/0")) {
                    self.$router.push({
                      name: "route-device-form",
                      params: {
                        connector_id: self.device.connector.id,
                        device_id: self.device.id
                      }
                    });
                  }
                  self.nav(self.device.name);
                } else {
                  // update dependencies before close it.
                  if (
                    (this.deviceList || []).some(
                      ({id, reference_device_id}) =>
                        id !== ret.id && reference_device_id == ret.id
                    )
                  ) {
                    this.$store.dispatch("dashboard/fetchDevices", {
                      connector_id: this.connectorId
                    });
                  }
                  this.close("save");
                }
              }, true);
            } else {
              this.showAlert();
            }
          });
        } else if (resp == "upgrade") {
          this.$router.push("/dashboard/plan");
        }
      });
    },
    clone() {
      let self = this;
      let payload = self.payload;
      delete payload.id;
      self.busy = true;
      this.service.duplicate(self.device_id, payload).then((ret) => {
        self.busy = false;
        if (self.validateSaveResponse(ret)) {
          self.onSaveSuccess(deviceListAdapter(ret), "device");
          if (!ret.is_reference && ret.reference_device_id) {
            // Define como Dispositivo Referenciado se estiver clonando e
            // o Dispositivo Coletor for outro
            let device = (
              this.$store.getters["dashboard/deviceList"] || []
            ).find(({id}) => id == ret.reference_device_id);
            if (device && !device.is_reference) {
              device.is_reference = true;
              this.$store.dispatch("dashboard/setDeviceValue", [device]);
            }

            // since original has been updated, refresh the connector list
            self.fetchDevices();
          }
          self.fetchDataList();
          self.showAlert(() => {
            self.close("clone");
          });
        } else {
          self.showAlert();
        }
      });
    },
    remove() {
      this.doRemove(this.payload, this.service, (removed) => {
        if (!removed) return;
        this.$store.dispatch("dashboard/removeResources", [
          {device_id: this.payload.id}
        ]);
      });
    },
    initData(device) {
      let self = this;
      if (device) {
        if (device.connector && device.connector.id) {
          // process area setup
          self.parent = {
            id: device.connector.id || "",
            name: device.connector.name || ""
          };
          self.process_area.inherits_parent_process_area =
            device.inherits_parent_process_area;
          self.process_area.id =
            (device.process_area && device.process_area.id) || "";

          device.local_storage_version_id =
            (device.local_storage_version && device.local_storage_version.id) ||
            (self.storageVersions.length && self.storageVersions[0].id);

          if (device.data_collector_device_id) {
            if (this.cloneEnabled) {
              this.keep_device_reference = true;
            }
          } else {
            // IMPORTANT: Do not rely on vue reactivity for computed isMQTT function since
            // device object has not been set yet;
            if (
              device?.connector?.protocol?.is_mqtt_protocol &&
              this.deviceCollectorList.length
            ) {
              device.data_collector_device_id = this.deviceCollectorList.sort(
                (a, b) => (a > b ? 1 : b > 1 ? -1 : 0)
              )[0].id;
            } else {
              device.data_collector_device_id = device.id;
            }
          }
          this.historyDataToggle = device?.data_to_enable_history_id
            ? true
            : false;
          device.label = this.$utils.trim(device.label);
          self.$set(self, "device", JSON.parse(JSON.stringify(device)));
          self.mqttLabelLocked = this.isVirtual ? false : device.label == "";
          self.originalExtendedProperties =
            this?.device?.portal_data?.extended_properties ?? null;
          if (this?.connector?.base_model_id) {
            extendedPropertiesDeviceAdapter(
              this.device,
              this.$store.getters["dashboard/deviceList"]
            );
            this.extendedPropertiesMode = "viewer";
            this.originalExtendedProperties =
              this?.device?.portal_data?.extended_properties ?? null;
          } else if (this?.connector?.base_model) {
            // connector model
            this.extendedPropertiesMode = "editor";
          } else {
            // regular connector
            this.extendedPropertiesSchema
              .filter(({name}) =>
                ["title", "description", "character_limit"].includes(name)
              )
              .forEach((f) => (f.enabled = false));
            this.extendedPropertiesMode = "editor";
          }
          self.ready = true;
          return;
        }
      }
      self.nav("invalid_data");
    },
    setup() {
      let self = this;
      if (self.device_id) {
        //read the device and the connector at once
        self.busy = true;
        self.fetchDevice().then((device) => {
          self.busy = false;
          if (!device) {
            self.noData();
            return;
          }
          deviceListAdapter(device);
          self.originalName = device.name;
          self.cloneEnabled = self.$utils.gup("a") == "c";
          self.initData(device);
          if (self.cloneEnabled) {
            self.nav(`${self.device.name} (${self.$t("copying")})`);
          } else if (this.$utils.gup("a") == "d") {
            self.nav(`${self.device.name} (${self.$t("deleting")})`);
            self.$nextTick(() => {
              self.onButtonRemoveClick();
            });
          } else {
            self.nav(self.device.name);
          }
        });
      } else {
        if (self.connectorId) {
          //read the connector, creates an dummy device and associate them
          self.busy = true;
          self.fetchConnector(self.connectorId).then((connector) => {
            self.busy = false;
            if (connector) {
              let device = defaultData().device;
              device.connector = connector;
              self.initData(device);
              self.nav(self.$tc("new"));
            }
          });
        } else {
          let device = defaultData().device;
          device.connector = {id: "0", name: ""};
          self.initData(device);
          self.nav(self.$tc("new"));
        }
      }
    },
    nav(title) {
      this.$emit("titleChanged", title);
      let device = this.device || null;
      let connector = (device && device.connector) || null;
      let connector_id = (connector && connector.id) || this.connectorId;
      var items = null;
      if (connector) {
        items = [
          {
            name: "connector_plural",
            url: `/dashboard/edit/connector`
          },
          {
            name: (connector && connector.name) || connector_id,
            url: `/dashboard/edit/connector/${connector_id}`
          },
          {
            name: "device_plural",
            url: `/dashboard/edit/connector/${connector_id}/device`
          },
          {name: device.name, url: ``}
        ];
      } else {
        items = [
          {
            name: "connector_plural",
            url: `/dashboard/edit/connector`
          },
          {
            name: connector_id,
            url: `/dashboard/edit/connector/${connector_id}`
          },
          {
            name: "device_plural",
            url: `/dashboard/edit/connector/${connector_id}/device`
          },
          {name: this.$tc("new"), url: ``}
        ];
      }
      this.$emit("navChanged", {
        previous: "/dashboard/edit/connector",
        items: items
      });
    },
    onEditScreen() {
      if (this.canEditScreen) {
        this.$router.push(`/dashboard/screen/edit/${this.device.screen_id}`);
      }
    },
    dataHistoryItems(items) {
      let lst = items;
      if (this.isVirtual) {
        lst = lst.filter(
          ({id, device}) =>
            (parseInt(id) &&
              device &&
              ((this.device.id &&
                parseInt(this.device.id) === parseInt(device.id)) ||
                parseInt(device.id) ===
                  parseInt(this?.device?.data_collector_device_id))) ||
            `${id}`.startsWith(`data_group_${this.device.id}`) ||
            `${id}`.startsWith(
              `data_group_${this?.device?.data_collector_device_id}`
            )
        );
      }
      return lst.filter(
        ({memory_size, id, is_local}) =>
          (memory_size == 1 && parseInt(id) && !is_local) ||
          `${id}`.startsWith("data_group_")
      );
    },
    toggleMqttLabel() {
      this.mqttLabelLocked = !this.mqttLabelLocked;
      this.label = this.mqttLabelLocked ? "" : this.device.name;
      if (!this.mqttLabelLocked) {
        this.$nextTick(
          () => this.$refs.inpLabel && this.$refs.inpLabel.focus()
        );
      }
    },
    onRestoreExtendedProperties() {
      if (!this.originalExtendedProperties) return;
      this.extendedProperties = structuredClone(
        this.originalExtendedProperties
      );
    }
  },
  created() {
    let self = this;
    this.service = new DeviceService();
    this.parentService = this.newConnectorService(!self.device_id);
    this.rule = "EstacaoCadastro";
  },
  mounted() {
    if (!this.isLoading) {
      this.setup();
    }
  },
  beforeDestroy() {
    this.service = null;
  }
};
</script>

<style scoped>
.top-10 {
  margin-top: 10px;
}
.top-30 {
  margin-top: 30px;
}
.overlay-local {
  z-index: 1;
}
.no-margin {
  margin: 0 !important;
}
.form-group-timezone::v-deep > form > .form-group > label {
  /* font-weight: 400; */
}
label {
  white-space: nowrap;
  /* font-weight: 400; */
}
.no-focus-border {
  border-right: 0;
  border-color: #d2d6de;
}

.no-focus-border:focus {
  outline-width: 0;
  padding-left: 0;
  border-left: 0;
}
.has-error .no-focus-border:focus {
  border-color: #dd4b39;
}

.input-prefix-label {
  padding-right: 0px;
  line-height: 1.42857143;
  font-weight: 600;
  color: #999;
}
.skin-dark .input-prefix-label {
  color: var(--skin-dark-light);
}

.skin-dark .input-prefix-label {
  color: var(--skin-dark-light);
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.skin-dark .clicable:hover {
  opacity: 1;
  color: #fff;
}
.skin-dark .clicable:hover {
  opacity: 1;
  color: #fff;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.operator-addon {
  width: 24%;
  padding: 0;
}

.operator-addon .caret {
  float: right;
  margin: 6px 6px 0 0;
}

.input-group-addon.disabled {
  background-color: #eee;
}
.disabled:hover {
  cursor: not-allowed;
}

div::v-deep > div > .form-group > .input-group > .form-control,
.input-group::v-deep > .form-control {
  z-index: inherit;
}

.skin-dark li > a .fa-device {
  background-color: var(--skin-dark-light);
}

.skin-dark li.active > a .fa-device,
.skin-dark li:not(.active) > a:hover .fa-device,
.skin-dark li:not(.active) > a:focus .fa-device {
  background-color: #fff;
}
</style>
