<template>
  <div class="form-group" :class="{'has-error': notFound}">
    <label v-if="label" for="screenIdSelector">{{ $t(label) }}</label>
    <div
      class="input-group"
      v-if="$scopedSlots.addonBefore || $scopedSlots.addonAfter"
    >
      <slot name="addonBefore" v-if="$scopedSlots.addonBefore">
        <div
          class="input-group-addon btn"
          @click.stop.prevent="$emit('addonClick', 0)"
        >
          <i class="fa fa-chevron-up"></i>
        </div>
      </slot>
      <v-select
        id="screenIdSelector"
        label="name"
        ref="inp"
        v-model="screenId"
        :disabled="$attrs.disabled ? true : false"
        :clearable="true"
        :options="items"
        :placeholder="$tc('select_a_screen', 1)"
        :reduce="(item) => item.id"
        @search:focus="$emit('focus')"
        class="style-chooser"
      >
        <span slot="no-options">{{ $t("no_result_found") }}</span>
      </v-select>
      <slot name="addonAfter" v-if="$scopedSlots.addonAfter">
        <div
          class="input-group-addon btn"
          @click.stop.prevent="$emit('addonClick', 1)"
        >
          <i class="fa fa-undo"></i>
        </div>
      </slot>
    </div>
    <v-select
      v-else
      id="screenIdSelector"
      label="name"
      ref="inp"
      v-model="screenId"
      :disabled="$attrs.disabled ? true : false"
      :clearable="true"
      :options="items"
      :placeholder="$tc('screen', 1)"
      :reduce="(item) => item.id"
      @search:focus="$emit('focus')"
      class="style-chooser"
    >
      <span slot="no-options">{{ $t("no_result_found") }}</span>
    </v-select>
  </div>
</template>

<script>
const parseValue = (v) =>
  v !== "" && v !== null && v !== undefined && !isNaN(v) ? parseInt(v) : "";
export default {
  name: "ScreenSelector",
  props: {
    value: {
      type: [String, Number],
      default: "",
      required: false
    },
    default: {
      type: [String, Number],
      default: undefined,
      required: false
    },
    label: {
      type: String,
      default: "",
      required: false
    },
    except: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      iValue: undefined
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"];
    },
    screenList() {
      let $can = this.$store.getters["user/hasUserAccessTo"] || (() => true);
      return (this.$store.getters["dashboard/screens"] || []).filter((s) =>
        !(s?.process_area?.id || 0) ? true : $can(s.process_area.id)
      );
    },
    items() {
      let exp = (this.except || []).map((i) => parseInt(i));
      return this.screenList
        .filter(
          ({id, deleted_at, portal_data}) =>
            id > 0 &&
            !deleted_at &&
            (!portal_data || !portal_data.excluded_at) &&
            (!exp.length || exp.indexOf(parseInt(id)) == -1)
        )
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    hasPrivateItems() {
      return !this.contract
        ? false
        : this?.items?.filter((item) => !item.public)?.length > 0;
    },
    screenId: {
      set(value) {
        let vlr = parseValue(value);
        if (this.iValue != vlr) {
          this.iValue = vlr;
          if (this.value && !vlr) this.$emit("reset");
          if (vlr != this.value) this.$emit("input", vlr);
        }
      },
      get() {
        const vlr = parseValue(this.iValue);
        return vlr !== "" ? vlr : parseValue(this.default);
      }
    },
    notFound() {
      if (!this.value) return false;
      let selected = this.items.find(
        ({id}) => parseInt(id) == parseInt(this.value)
      );
      return Boolean(!selected);
    }
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        if (this.$refs.inp) {
          this.$refs.inp.onSearchFocus();
        }
      });
    },
    fetchScreens() {
      this.$store.dispatch("dashboard/fetchScreens", {
        contract_id: this.contract.id
      });
    }
  },
  watch: {
    value: {
      handler(n) {
        if (parseValue(n) !== parseValue(this.iValue)) {
          this.screenId = n;
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (!this.hasPrivateItems) {
      setTimeout(
        () => !this?.hasPrivateItems && this.fetchScreens(),
        2000,
        this
      );
    }
  }
};
</script>
<style scoped>
.input-group {
  max-width: inherit;
}
</style>
<style>
.has-error .vs__dropdown-toggle {
  color: #dd4b39 !important;
  border-color: #dd4b39 !important;
}

.has-error .vs__clear,
.has-error .vs__open-indicator {
  fill: #dd4b39 !important;
}

.has-error .fa {
  color: #dd4b39 !important;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  padding: 0;
  border-radius: 0px; /* AdminLTE does not make use of rounded border */
}

.style-chooser .vs__dropdown-toggle {
  height: 34px;
}

.style-chooser .vs__search {
  padding: 4px 10px;
  line-height: 1.4;
}

.style-chooser .vs__selected {
  /* color: red; */
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: -60px;
  overflow: hidden;
  width: 100%;
}

.vs--disabled .vs__selected {
  margin-right: 0;
}

.vs--disabled .vs__search {
  display: none;
}

:root {
  --vs-disabled-bg: #eee;
}
</style>
